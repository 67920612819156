body {
  margin: 0;
}

div:hover::-webkit-scrollbar-thumb {
  background: #1976d2;
}

section:hover::-webkit-scrollbar-thumb {
  background: #1976d2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 18px;
}

p {
  margin-bottom: 0px;
}

.dndflow {
  flex: 1;
}

/* set z-index to -1 for the iframe blocking the root div */
body>#root~script:last-of-type+iframe {
  z-index: -1 !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-noOptions,
.MuiAutocomplete-popper .MuiAutocomplete-groupLabel {
  color: #222222;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.MuiButton-root {
  text-transform: inherit !important;
}

.react-flow__node {
  z-index: -1 !important;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #1976d2;
  }
  
  50% {
    box-shadow: 0 0 20px 0 #1976d2;
  }
}

@keyframes glow-small {
  0% {
    box-shadow: 0 0 0 0 #1976d2;
  }
  
  50% {
    box-shadow: 0 0 8px 0 #1976d2;
  }
}