@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-UltraLight.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-UltraLightItalic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-Regular.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-Italic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-Medium.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-MediumItalic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-DemiBold.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-Bold.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-BoldItalic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-Heavy.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./avenirNext/AvenirNext-HeavyItalic.woff2') format('woff2'),
    url('./avenirNext/AvenirNext-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
